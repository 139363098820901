<template>
  <div>
    <v-card
      class="mx-auto mt-6"
      outlined
      v-if="loadedCnt === loadedStates.completed"
    >
      <v-card-title>Card Authorisation Status</v-card-title>

      <v-card-text class="headline font-weight-bold">
        {{ CreditCardTransactionResults.ReasonCodeDescription }}
      </v-card-text>

      <v-card-actions v-show="!!cardData">
        <v-btn @click="loadedCnt = 0">Press To Continue</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mx-4 mt-6" outlined v-else-if="!!hostedUrl">
      <v-card-title>Card Registration</v-card-title>
      <iframe style="width: 100%" :src="hostedUrl" @load="checkUrl"></iframe>
    </v-card>

    <v-card class="mx-4 mt-6" outlined v-else>
      <v-card-title>Debit/Credit Card</v-card-title>
      <v-list flat>
        <v-list-item-group color="primary">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-credit-card-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{
                  CreditCardTransactionResults.PaddedCardNumber ||
                  "No Registered Card"
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-actions>
        <v-btn class="mr-6" :loading="!!loadedCnt" @click="verifyCard">{{
          actionTitle
        }}</v-btn>

        <manage-customer-card
          v-if="!isActiveLoan"
          :userid="userid"
          :padded-card-number="CreditCardTransactionResults.PaddedCardNumber"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
const loadedStates = {
  init: 0,
  gettingHostedUrl: 1,
  monitoringUrl: 2,
  completed: 3,
};
///0 == init
//1 = gethostedId
//2= monitoring Url
//3=
import ManageCustomerCard from "@/components/ManageCustomerCard";
import graphqlMixin from "@/mixins/graphql-mixin.js";
const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid.toUpperCase();
};
export default {
  components: { ManageCustomerCard },
  mixins: [graphqlMixin],
  data: () => ({
    loadedCnt: loadedStates.init,
    hostedId: null,
    loadedStates,
  }),

  computed: {
    isActiveLoan() {
      return this.$store.getters["loan/isActiveLoan"];
    },
    hostedUrl() {
      return this.$store.state.profile.hostedUrl;
    },

    userid() {
      return this.$store.getters["account/userid"];
    },

    cardData() {
      return this.$store.state.profile.cardData;
    },

    CreditCardTransactionResults() {
      return this.$store.getters["profile/CreditCardTransactionResults"];
    },
    actionTitle() {
      return this.CreditCardTransactionResults.PaddedCardNumber
        ? "Update Card"
        : "Register Card";
    },
  },

  watch: {
    hostedUrl: function (val) {
      if (val) {
        this.loadedCnt = loadedStates.monitoringUrl;
      }
    },
    cardData: {
      handler: function (data) {
        console.log("watch--cardData-->", data);
        if (this.loadedCnt === loadedStates.monitoringUrl) {
          this.$store.commit("profile/STOP_CARD_SUBSCRIPTION");
          this.loadedCnt = loadedStates.completed;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.hostedId = generateUUID();
    console.log("loadedCnt", this.loadedCnt);
  },

  methods: {
    verifyCard() {
      this.loadedCnt = loadedStates.gettingHostedUrl;
      this.$store.dispatch("profile/getHostedPaymentUrl", this.hostedId);
    },

    checkUrl(evt) {
      //this.contentWindow.location
      console.log("checkUrl event", evt);
      this.$store.dispatch("profile/monitorCardPreAuthConfirmation");
      //this.loadedCnt = loadedStates.monitoringUrl;
      console.log("loadedCnt", this.loadedCnt);
    },
  },
};
</script>

<style scoped>
iframe {
  border: 0;
  width: 100%;
  height: 1000px;
}
</style>
